import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Hero = () => {
    const data = useStaticQuery(graphql`
    query {
      heroimage: file(relativePath: { eq: "optimization-flex.png" }) {
        childImageSharp {
          fluid(maxWidth: 1540) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    `)
    return (
        <div className="hero-applications">
            <div className="container">
            <div className='column'>
                    <div>
                    <div className="main-text">
                    Flexibele <span>optimalisatie.</span>
                    </div>
                    <div className="sub-text">
                    Verwerk alle restricties en voorkeuren voor een optimale planning. 
                    </div>
                    </div>
                    <div className='child'>
                        <p>De route die het voertuig rijdt om het afval op te halen is afhankelijk van vele factoren. Bijvoorbeeld
                        van de tijdsafspraken met de klant, het type voertuig, de restricties in de stad, en de voorkeuren van de chauffeur. </p>
                    </div>
                    <div className = 'sideimage'> 
                  <Img className = 'graphic' fluid={data.heroimage.childImageSharp.fluid} />
                </div>
                    <div className='child'>
                    <h3> Voor ieder probleem. </h3>
                    <p>Met behulp van onze optimalisatie algoritmen kunnen alle relevante restricties 
                        en voorkeuren gemakkelijk in de routes verwerkt worden. Voor zowel kleine als grote problemen, 
                        van tientallen tot duizenden klanten. </p>
                    </div>
                    <div className='child right'>
                    <h3> Verwerk alle restricties. </h3>
                    <p>Van venstertijden in de stad, de breedte van het voertuig en de weg, de voorkeuren van de chauffeurs - het vinden van de 
                    meest optimale en comfortabele route was nog nooit zo makkelijk. </p>
                    </div>
                    <div className='child'>
                    <h3> Klassiek en state-of-the-art. </h3>
                    <p>Onze algoritmen zijn gebaseerd op een gedailleerde graaf, oftewel kaart, van de stad. In deze kaart verwerken we 
                    alle restricties die relevant zijn voor de planning, alsmede de historische rijtijden zoals bepaald uit de GPS data uit DispatchX. 
                    Vervolgens gebruiken we een combinatie van de klassieke algoritmen en state-of-the-art technieken voor het optimaliseren van de routes. </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero

